import { useCallback, useEffect, useState } from 'react'

import { AADServerParamKeys } from '@azure/msal-common/browser'
import { captureEvent } from '@sentry/browser'
import {
  ReceivedAzureTokenMutation,
  ReceivedAzureTokenMutationVariables,
} from 'types/graphql'

import { Metadata, useMutation } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

export const SENTIMENT_AZURE_AUTH_CHANNEL =
  'sentiment_analysis_azure_authorisation'
export const SENTIMENT_AZURE_AUTH_CHANNEL_SUCCESS = 'Success'
export const SENTIMENT_AZURE_AUTH_CHANNEL_FAILED = 'Failed'

const RECEIVED_AZURE = gql`
  mutation ReceivedAzureTokenMutation($input: AzureReceivedAppCodeInput!) {
    azureReceivedAppCode(input: $input)
  }
`

/**
 * Ensure the URI for the domain is registered in the Azure App Registration
 * https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Authentication/appId/6df454c8-aede-4ca5-bc99-bc72799336d6/isMSAApp~/false
 */
const AzureAppAuthPage = () => {
  const [showError, setShowError] = useState(false)
  const [showLoading, setShowLoading] = useState(true)

  const urlParams = new URLSearchParams(window.location.search)
  const code = urlParams.get(AADServerParamKeys.CODE)
  const clientInfo = urlParams.get(AADServerParamKeys.CLIENT_INFO)
  const state = urlParams.get(AADServerParamKeys.STATE)
  const error = urlParams.get(AADServerParamKeys.ERROR)

  const sendBroadcast = useCallback((status: string) => {
    const bc = new BroadcastChannel(SENTIMENT_AZURE_AUTH_CHANNEL)
    bc.postMessage(status)
    bc.close()
  }, [])

  const [receivedAzureToken] = useMutation<
    ReceivedAzureTokenMutation,
    ReceivedAzureTokenMutationVariables
  >(RECEIVED_AZURE, {
    onCompleted: () => {
      setShowLoading(false)
      sendBroadcast(SENTIMENT_AZURE_AUTH_CHANNEL_SUCCESS)
      window.close()
    },
    onError: (error) => {
      captureEvent({
        message: 'Failed to save Azure token',
        level: 'error',
        extra: { error, clientInfo, state },
      })
      setShowError(true)
      setShowLoading(false)
      sendBroadcast(SENTIMENT_AZURE_AUTH_CHANNEL_FAILED)
      window.close()
    },
  })

  useEffect(() => {
    if (error) {
      captureEvent({
        message: 'Failed to save Azure token',
        level: 'error',
        extra: {
          error,
          clientInfo,
          state,
        },
      })
    }

    const saveToken = async () => {
      if (!code || !state || !clientInfo || error) {
        captureEvent({
          message: 'Failed to retrieve Azure token details from query string',
          level: 'error',
          extra: { error, clientInfo, state },
        })
        setShowError(true)
        sendBroadcast(SENTIMENT_AZURE_AUTH_CHANNEL_FAILED)
        window.close()
      } else {
        await receivedAzureToken({
          variables: {
            input: {
              code,
              clientInfo: clientInfo,
              state,
            },
          },
        })
      }
    }

    saveToken()
  }, [])

  return (
    <>
      <Metadata title="AzureResponse" description="AzureResponse page" />

      <div className="grid min-h-screen place-items-center">
        {showError && (
          <p className="text-center">
            There was an error processing your authentication request.
            <br />
            Please close this tab and try again.
          </p>
        )}
        {!showError && showLoading && (
          <>
            <p className="text-center">
              Your authentication request is processing.
              <br />
              Please do not close this tab.
            </p>
            <LoadingSpinner />
          </>
        )}
        {!showError && !showLoading && (
          <p className="text-center">
            Your authentication request was successful.
            <br />
            You can now close this tab.
          </p>
        )}
      </div>
    </>
  )
}

export default AzureAppAuthPage
